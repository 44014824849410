/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Subtitle, Title, Text, Button, Image, ContactForm } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="--style3 --full" anim={""} name={"intro"} animS={"5"} style={{"backgroundColor":"rgba(239,245,252,1)"}} fullscreen={true} css={css`
      background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper animS={"3"}>
              
              <Subtitle className="subtitle-box fs--30 w--400" content={"<span style=\"color: rgb(240, 35, 60);\">Potěšte své partnery. Zašlete jim</span>"}>
              </Subtitle>

              <Title className="title-box fs--102 lh--1 mt--16" style={{"maxWidth":922}} content={"<span style=\"color: rgb(51, 55, 90);\">Online přání k</span>&nbsp;<span style=\"color: rgb(51, 55, 90);\">novému roku</span><br>"}>
              </Title>

              <Text className="text-box fs--20" style={{"maxWidth":786}} content={"<span style=\"color: rgb(51, 55, 90);\">Vytvoříme Vám online přání PF 2023 na míru. Vaše přání obdržíte na vlastní www adrese, jako obrázek ve formátu .png, a jako .pdf soubor.&nbsp;</span>"}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--shape5 fs--20 mt--20" href={"/#ukazky"} content={"Ukázky přání PF2023"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center mb--0 mt--0 pb--0 pt--0" name={"1rx64nmg849"} style={{"backgroundColor":"rgba(239,245,252,1)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s3 --left pb--0 pt--0" anim={"2"} animS={"3"}>
              
              <Image className="mb--0 mt--0" alt={""} src={"https://cdn.swbpg.com/t/11212/25260ba70bcc4bd58a6a8c3931701471_s=860x_.png"} sizes={"100vw"} style={{"maxWidth":1440}} srcSet={"https://cdn.swbpg.com/t/11212/25260ba70bcc4bd58a6a8c3931701471_s=350x_.png 350w, https://cdn.swbpg.com/t/11212/25260ba70bcc4bd58a6a8c3931701471_s=660x_.png 660w, https://cdn.swbpg.com/t/11212/25260ba70bcc4bd58a6a8c3931701471_s=860x_.png 860w, https://cdn.swbpg.com/t/11212/25260ba70bcc4bd58a6a8c3931701471_s=1400x_.png 1400w, https://cdn.swbpg.com/t/11212/25260ba70bcc4bd58a6a8c3931701471_s=2000x_.png 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"peje0p4h0q8"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":360}}>
              
              <Subtitle className="subtitle-box fs--18" content={"<span style=\"color: rgb(240, 35, 60);\">vaseprani.pf23.cz</span>"}>
              </Subtitle>

              <Title className="title-box fs--43 mt--10" content={"Přání na vlastní webové adrese"}>
              </Title>

              <Text className="text-box" content={"Vaše elektronické přání bude dostupné na adrese vamizvolene.pf23.cz&nbsp;"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":360}}>
              
              <Subtitle className="subtitle-box fs--18" content={"<span style=\"color: rgb(240, 35, 60);\">Na jeden klik</span>"}>
              </Subtitle>

              <Title className="title-box fs--43 mt--10" content={"Snadné zaslání přes e-mail"}>
              </Title>

              <Text className="text-box" content={"E-mail s elektronickým přání rozesílejte všem komu chcete, zcela bez omezení."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":360}}>
              
              <Subtitle className="subtitle-box fs--18" content={"<span style=\"color: rgb(240, 35, 60);\">Všechno potřebné</span>"}>
              </Subtitle>

              <Title className="title-box fs--43 mt--10" content={"Formáty .JPG, .PNG a .PDF"}>
              </Title>

              <Text className="text-box" content={"E-mailem Vám zašleme i obrázek&nbsp; elektronického přání, který si můžete vytisknout nebo odeslat e-mailem."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--12" name={"nnuh2ulk7es"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":590}}>
              
              <Title className="title-box" content={"Blíží se čas vánočních svátků a oslav nového roku 2023. Přemýšlíte, jak blízkým připomenout, že na ně myslíte?\n"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":450}} content={"Ukažte svým blízkým, rodině a přátelům, že vám na nich záleží. Poděkujte svým obchodním partnerům za spolupráci a popřejte jim hodně úspěchů do nového roku přes PF 2023.\n"}>
              </Text>

              <Button className="btn-box btn-box--shape5" href={"/#poptat"} content={"Poptat vytvoření PF 2023"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/11212/a6176cbd1820460ab496433ce9024004_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/a6176cbd1820460ab496433ce9024004_s=350x_.png 350w, https://cdn.swbpg.com/t/11212/a6176cbd1820460ab496433ce9024004_s=660x_.png 660w, https://cdn.swbpg.com/t/11212/a6176cbd1820460ab496433ce9024004_s=860x_.png 860w, https://cdn.swbpg.com/t/11212/a6176cbd1820460ab496433ce9024004_s=1400x_.png 1400w, https://cdn.swbpg.com/t/11212/a6176cbd1820460ab496433ce9024004_s=2000x_.png 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center mb--0 mt--0 pb--0 pt--0" name={"iu50j4dnew"}>
          
          <ColumnWrap className="column__flex --center el--1 --full" columns={"1"} fullscreen={true}>
            
            <ColumnWrapper className="--left pb--0 pt--0">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"ukazky"} style={{"backgroundColor":"rgba(239,245,252,1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex js-anim  --anim7 --anim-s3 --center el--1" anim={"7"} animS={"3"} style={{"maxWidth":1448}} columns={"1"}>
            
            <ColumnWrapper className="pb--20 pt--20" style={{"maxWidth":866}}>
              
              <Title className="title-box fs--62 lh--1 mt--08" style={{"maxWidth":834}} content={"<span style=\"color: rgb(51, 55, 90);\">Jak PF 2023 vypadají</span><br>"}>
              </Title>

              <Subtitle className="subtitle-box mt--12" content={"<span style=\"color: rgb(240, 35, 60);\">Pospěšte si s objednávkou, volné kapcity rychle ubívají.</span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Ukázka PF 2023"} src={"https://cdn.swbpg.com/t/11212/a094d216582644439722adae92ffdc27_e=0x0x800x533_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/a094d216582644439722adae92ffdc27_e=0x0x800x533_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/11212/a094d216582644439722adae92ffdc27_e=0x0x800x533_s=660x_.jpeg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Ukázka PF 2023"} src={"https://cdn.swbpg.com/t/11212/617e46c3178c4dde8709b00dbac9fb02_e=0x0x800x533_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/617e46c3178c4dde8709b00dbac9fb02_e=0x0x800x533_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/11212/617e46c3178c4dde8709b00dbac9fb02_e=0x0x800x533_s=660x_.jpeg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Ukázka PF"} src={"https://cdn.swbpg.com/t/11212/95bb7458379e4134b00c93c858ec438c_e=0x0x779x519_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/95bb7458379e4134b00c93c858ec438c_e=0x0x779x519_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/11212/95bb7458379e4134b00c93c858ec438c_e=0x0x779x519_s=660x_.jpeg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Ukázka PF 2023"} src={"https://cdn.swbpg.com/t/11212/492ed3240eb347c988b5a533072be736_e=39x0x708x472_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/492ed3240eb347c988b5a533072be736_e=39x0x708x472_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/11212/492ed3240eb347c988b5a533072be736_e=39x0x708x472_s=660x_.jpeg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Ukázka PF 2023"} src={"https://cdn.swbpg.com/t/11212/34f025f33b7f4cdda208678d7abebcdf_e=0x0x800x533_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/34f025f33b7f4cdda208678d7abebcdf_e=0x0x800x533_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/11212/34f025f33b7f4cdda208678d7abebcdf_e=0x0x800x533_s=660x_.jpeg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Ukázka PF 2023"} src={"https://cdn.swbpg.com/t/11212/385e1635044d42c088470f7818a82cb4_e=60x0x677x451_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/385e1635044d42c088470f7818a82cb4_e=60x0x677x451_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/11212/385e1635044d42c088470f7818a82cb4_e=60x0x677x451_s=660x_.jpeg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Ukázka PF 2023"} src={"https://cdn.swbpg.com/t/11212/f945b57ea164441f91782b258b6b3747_e=0x0x800x533_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/f945b57ea164441f91782b258b6b3747_e=0x0x800x533_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/11212/f945b57ea164441f91782b258b6b3747_e=0x0x800x533_s=660x_.jpeg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Ukázka PF 2023"} src={"https://cdn.swbpg.com/t/11212/522c7143d9ba47218b51d22065f6aebc_e=158x0x2334x1556_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/522c7143d9ba47218b51d22065f6aebc_e=158x0x2334x1556_s=350x_.png 350w, https://cdn.swbpg.com/t/11212/522c7143d9ba47218b51d22065f6aebc_e=158x0x2334x1556_s=660x_.png 660w, https://cdn.swbpg.com/t/11212/522c7143d9ba47218b51d22065f6aebc_e=158x0x2334x1556_s=860x_.png 860w, https://cdn.swbpg.com/t/11212/522c7143d9ba47218b51d22065f6aebc_e=158x0x2334x1556_s=1400x_.png 1400w, https://cdn.swbpg.com/t/11212/522c7143d9ba47218b51d22065f6aebc_e=158x0x2334x1556_s=2000x_.png 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Ukázka PF 2023"} src={"https://cdn.swbpg.com/t/11212/5c365be60880416ab52069e1df0740b8_e=48x0x714x476_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/5c365be60880416ab52069e1df0740b8_e=48x0x714x476_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/11212/5c365be60880416ab52069e1df0740b8_e=48x0x714x476_s=660x_.jpeg 660w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"3kgy54zkgqi"} style={{"backgroundColor":"rgb(240, 35, 60, 1)"}}>
          
          <ColumnWrap className="column__flex el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":595}}>
              
              <Subtitle className="subtitle-box" style={{"color":"var(--color-variable-1)"}} content={"<span style=\"color: rgb(254, 244, 245);\">Nemáte nyní prostor?</span>"}>
              </Subtitle>

              <Title className="title-box" content={"<span style=\"color: rgb(255, 255, 255);\">Zanechte nám kontakt. Ozvěme se Vám.</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style1 --right" action={"/contact"} fields={[{"name":"Vaše jméno","type":"text","required":true,"placeholder":"Vaše jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Kontaktní e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefonní číslo"},{"name":"Zavolejte mi","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"cenik"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"color: rgb(51, 55, 90);\">Ceník</span>"}>
              </Title>

              <Subtitle className="subtitle-box mt--12" content={"<span style=\"color: rgb(240, 35, 60);\">Pospěšte si s objednávkou, volné kapacity rychle ubývají.</span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--2 mt--40 flex--center" anim={"2"} animS={"3"} style={{"maxWidth":1090}} columns={"2"}>
            
            <ColumnWrapper className="--shape5 pb--30 pl--30 pr--30 pt--30" style={{"backgroundColor":"rgba(239,245,252,1)"}}>
              
              <Title className="title-box" content={"<span style=\"color: rgb(51, 55, 90);\">Elektronické Přání</span>"}>
              </Title>

              <Subtitle className="subtitle-box mt--12" content={"<span style=\"color: rgb(240, 35, 60);\">2 490 Kč</span>"}>
              </Subtitle>

              <Text className="text-box mt--0" content={"bez DPH"}>
              </Text>

              <Text className="text-box mt--12" content={"Popřejte svým partnerům všechno dobré.&nbsp;Naši profíci se postarají o to, abyste měli své elektronické přání. Dodání do 4 pacovních dnů."}>
              </Text>

              <Text className="text-box fs--18 mt--12" content={"<span style=\"font-weight: bold; color: rgb(240, 35, 60);\">✓</span>&nbsp; &nbsp;<span style=\"font-weight: bold;\">elektronické přáni</span>"}>
              </Text>

              <Text className="text-box fs--18 mt--0" content={"<span style=\"font-weight: bold;\"><span style=\"color: rgb(240, 35, 60);\">✓</span>&nbsp; &nbsp;vlastní adresa xxxxx.pf23.cz</span>"}>
              </Text>

              <Text className="text-box fs--18 mt--0" content={"<span style=\"font-weight: bold;\"><span style=\"color: rgb(240, 35, 60);\">✓</span>&nbsp; &nbsp;soubory .PDF, .PNG, .JPG</span>"}>
              </Text>

              <Text className="text-box fs--18 mt--0" content={"<span style=\"font-weight: bold;\"><span style=\"color: rgb(240, 35, 60);\">✓</span>&nbsp; &nbsp;neomezené zasílání e-mailem</span>"}>
              </Text>

              <Text className="text-box fs--18 mt--0" content={"<span style=\"font-weight: bold;\"><span style=\"color: rgb(240, 35, 60);\">✓</span>&nbsp; &nbsp;neomezené zasílání odkazem</span>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn4 btn-box--shape5 fs--20 mt--20" content={"Poptat nyní"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--shape5 pb--30 pl--30 pr--30 pt--30" style={{"backgroundColor":"rgba(239,245,252,1)"}}>
              
              <Title className="title-box" content={"<span style=\"color: rgb(51, 55, 90);\">Elektronické + </span><span style=\"font-style: italic; color: rgb(240, 35, 60);\">Tištěné</span><span style=\"color: rgb(51, 55, 90);\"> Přání</span>"}>
              </Title>

              <Subtitle className="subtitle-box mt--12" content={"<span style=\"color: rgb(240, 35, 60);\">3 290 Kč</span>"}>
              </Subtitle>

              <Text className="text-box mt--0" content={"bez DPH"}>
              </Text>

              <Text className="text-box mt--12" content={"Popřejte svým partnerům všechno dobré. Naši profíci se postarají o to, abyste měli své elektronické i reálně vytištěné přání.<br>Dodání do 4 pacovních dnů. (bez tisku)<br>"}>
              </Text>

              <Text className="text-box fs--18 mt--12" content={"<span style=\"font-weight: bold; color: rgb(240, 35, 60);\">✓</span>&nbsp; &nbsp;<span style=\"font-weight: bold;\">elektronické přáni</span>"}>
              </Text>

              <Text className="text-box fs--18 mt--0" content={"<span style=\"font-weight: bold;\"><span style=\"color: rgb(240, 35, 60);\">✓</span>&nbsp; &nbsp;vlastní adresa xxxxx.pf23.cz</span>"}>
              </Text>

              <Text className="text-box fs--18 mt--0" content={"<span style=\"font-weight: bold;\"><span style=\"color: rgb(240, 35, 60);\">✓</span>&nbsp; &nbsp;soubory .PDF, .PNG, .JPG</span>"}>
              </Text>

              <Text className="text-box fs--18 mt--0" content={"<span style=\"font-weight: bold;\"><span style=\"color: rgb(240, 35, 60);\">✓</span>&nbsp; &nbsp;neomezené zasílání e-mailem</span>"}>
              </Text>

              <Text className="text-box fs--18 mt--0" content={"<span style=\"font-weight: bold;\"><span style=\"color: rgb(240, 35, 60);\">✓</span>&nbsp; &nbsp;neomezené zasílání odkazem</span>"}>
              </Text>

              <Text className="text-box fs--18 mt--0" content={"<span style=\"font-weight: bold; color: rgb(240, 35, 60);\">✓&nbsp; &nbsp;<span style=\"font-style: italic;\">+10 ks tištěného přáni (formát A6)</span></span>"}>
              </Text>

              <Text className="text-box fs--18 mt--0" content={"<span style=\"font-weight: bold; color: rgb(240, 35, 60);\">✓&nbsp; &nbsp;<span style=\"font-style: italic;\">další kusy za 40kč/ks</span></span>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn4 btn-box--shape5 fs--20 mt--20" content={"Poptat nyní"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"poptat"} style={{"backgroundColor":"rgba(239,245,252,1)"}}>
          
          <ColumnWrap className="column__flex --center el--1 pb--10 pt--10" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"color: rgb(51, 55, 90);\">Objednávka</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper style={{"paddingTop":0}}>
              
              <Text className="text-box fs--14" style={{"marginTop":0,"marginBottom":17}} content={"Odesláním formuláře souhlasíte se zpracováním osobních údajů."}>
              </Text>

              <ContactForm className="--shape2 --shape3 fs--18" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Jméno a příjmení","type":"text","required":true,"placeholder":"Jméno a příjmení"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Kontaktní e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefonní číslo"},{"name":"IČ:","type":"text"},{"name":"Typ přání","type":"select","options":["Elektronické přání — 2490 Kč bez DPH","Elektromické + 20ks tištěné přání — 3290 Kč bez DPH"],"required":true,"placeholder":"Typ přání"},{"name":" Expresní zpracování do 24h od zaslání podkladů","type":"select","options":["NE","ANO + 800Kč"],"placeholder":" Expresní zpracování do 24h od zaslání podkladů"},{"name":"Objednat přání","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--20 pt--50" name={"1v0jeck5xzj"} style={{"backgroundColor":"rgba(51,55,90,1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex el--2" columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":590}}>
              
              <Title className="title-box fs--30" content={"<span style=\"color: rgb(255, 255, 255);\">Online přání k Novému roku 2023</span><br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":529}} content={"<span style=\"color: rgb(230, 236, 244);\">Vytvoříme Vám online přání PF 2023 na míru. Vaše přání obdržíte na vlastní www adrese, jako obrázek ve formátu .png, a jako .pdf soubor.&nbsp;</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Title className="title-box fs--30" content={"<span style=\"color: rgb(255, 255, 255);\">PF 2023</span><br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":529}} content={"<span style=\"color: rgb(207, 208, 209);\">tvorba </span><a href=\"https://saywebpage.com/cs/pf2023/?utm_source=pf23cz&amp;utm_medium=web&amp;utm_campaign=web_pf23cz\" style=\"color: rgb(187, 187, 188);\">PF 2023</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box text-box--center fs--18 ls--04" style={{"maxWidth":529}} content={"<span style=\"color: rgb(230, 236, 244); font-style: italic;\">PF na míru zákazníkům tvoříme od roku 2021.</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}